/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

	body {
		font-size: 12pt;
	}

	h1 {
		font-size: 140%;
	}

	h4 {
		font-size: 120%;
	}

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {

}
